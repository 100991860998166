import { motion } from "framer-motion";
import AppContext from "../context/AppContext";
import { useContext } from "react";

const Home = () => {
    const {intro, elem} = useContext(AppContext);


    return (
        <div className="work-main">
            <motion.div               
            className="intro"
            variants={intro}
            initial="hidden"
            animate="visible">
                <motion.h1 variants={elem}>Hi. I'm <span>Marco.</span></motion.h1>
                <motion.h1 variants={elem}>Software Engineer.</motion.h1>
                <motion.p variants={elem}>I'm passionate about crafting ideas into virtual reality.</motion.p>
            </motion.div>
            <div className="proj-main">
                <h1>Projects.</h1>
                <div className="proj-row">
                    <div className="proj2">
                        <a href="https://main.d176nstk7z8ek4.amplifyapp.com/" target="blank_">
                            <h2>Blogify</h2>
                        </a>
                        <div id="p" className="p-1"/>
                    </div>
                    <div className="proj1">
                        <a href="https://main.d19vho986892bs.amplifyapp.com/" target="blank_">
                            <h2>Mathlete</h2>
                        </a>
                        <div id="p" className="p-3"/>
                    </div>

                </div>
                <div className="proj-row">
                    <div className="proj1">
                        <a href="https://github.com/MarcoDayz/Distrib" target="blank_">
                            <h2>Distrib</h2>
                        </a>
                        <div id="p" className="p-2"/>
                    </div>
                    <div className="proj2">
                        <a href="https://master.d2fxrp3ub448ns.amplifyapp.com/" target="blank_">
                            <h2>Typesy</h2>
                        </a>
                        <div id="p" className="p-4"/>
                    </div>
                </div>
            </div>
            
            <div
            className="underway-main">
                <div>
                    <h1>Skills.</h1>
                    <div className="skills-wrapper">
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"/>
                            <h3>Javascript</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"/>
                            <h3>React</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"/>
                            <h3>HTML5</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"/>
                            <h3>CSS3</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg"/>
                            <h3>Bootstrap</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg"/>
                            <h3>NodeJs</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg"/>
                            <h3>Express</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg"/>
                            <h3>PostgreSQL</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg"/>
                            <h3>MySQL</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nginx/nginx-original.svg"/>
                            <h3>Nginx</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg"/>
                            <h3>Docker</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg"/>
                            <h3>VSCode</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <h1>Underway.</h1>
                    <p>Striving to develop in various programming languages, to final product design and development products.</p>
                    <div className="underway-wrapper">
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg"/>
                            <h3>Java</h3>
                        </div>
                        <div className="icon-wrapper">
                            <img className="icon" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg"/>
                            <h3>Spring</h3>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;