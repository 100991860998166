// import AppContext from "../context/AppContext";
// import { useContext } from "react";

const Timelines = () => {
    // const {elem} = useContext(AppContext);

    return (
        <div className="tl">
            <h1>Experience.</h1>
            <div className="tl-main">
                <div id="xp" className="xp-6">
                    <div>
                        <h4>Galvanize</h4>
                        <p>Software Engineer</p>
                    </div>
                    <p>22</p>
                </div>
                <div id="xp" className="xp-5">
                    <div>
                        <h4>160th SOAR</h4>
                        <p>Sr HR Assisant</p>
                    </div>
                    <p>19 - 22</p>
                </div>
                <div id="xp" className="xp-4">
                    <div>
                        <h4>I Corps</h4>
                        <p>HR Plans and Operations</p>
                    </div>
                    <p>18 - 19</p>
                </div>
                <div id="xp" className="xp-3">
                    <div>
                        <h4>4th ID</h4>
                        <p>HR Personnel Services</p>
                    </div>
                    <p>16 - 18</p>
                </div>
                <div id="xp" className="xp-2">
                    <div>
                        <h4>2nd ID</h4>
                        <p>HR Specialist</p>
                    </div>
                    <p>15 - 16</p>
                </div>
                <div id="xp" className="xp-1">
                    <div>
                        <h4>4th ID</h4>
                        <p>HR Specialist</p>
                    </div>
                    <p>12 - 15</p>
                </div>
        </div>
      </div>

    )
}

export default Timelines;