import { createContext, useState } from "react";

const AppContext = createContext();


export const AppContextProvider = ({children}) => {
    const [navbar, setNavbar] = useState(window.scrollY === 0 ? false : true);

    const intro = {
        hidden: { opacity: 1 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
          }
        }
    }

    const elem = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1
        }
    }

    return (
        <AppContext.Provider
        value={{navbar, setNavbar,
        intro, elem}}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;